import { Injectable } from "@angular/core";
import { SLAContractDetailsDTO, SLAContractFormDTO, SLAContractListDTO } from "../dto/sla-contract.dto";
import { MainConversionInterface } from "../../../../core/interfaces/conversion/main-conversion.interface";
import { CurrentUserPreferenceStore } from "../../../../core/store/current-user-preference.store";
import { TableConversion } from "../../../../shared/conversion/table.conversion";
import { UserConversion } from "../../../organization/users/conversion/user.conversion";
import { FileConversion } from "../../../../shared/conversion/file.converstion.dto";
import { FormGroup } from "@angular/forms";
import { convertResponseToLifeCycleStatusDetailsDTO } from "../../../settings/masters/dto/life-cycle-status.dto";
import { convertResponseToSeverityLevelDetailsDTO } from "../../../settings/masters/dto/severity-level.dto";
import { convertResponseToSlaCategoryDetailsDTO } from "../../../settings/compliance/dto/sla-category.dto";

@Injectable({
    providedIn: 'root',
  })
  export class SLAContractConversion
    implements
      MainConversionInterface<
        SLAContractListDTO,
        SLAContractFormDTO,
        SLAContractDetailsDTO
      >
  {
    private lang: string;
  
    constructor(
      private currentUserPreferenceStore: CurrentUserPreferenceStore,
      private tableConv: TableConversion,
      private userConv: UserConversion,
      private fileConv: FileConversion
    ) {
      this.lang = this.currentUserPreferenceStore.languageKey;
    }

    resToList(response: any[]): SLAContractListDTO[] {
        if (!response) {
          return [];
        }
        return response.map((item) => {
            return {
                id: item.id,
                reference_number: item.reference_number,
                standard_code: item.standard_code,
                title: item[`title_${this.lang}`],
                description: item.description,
                sla_category_title: item[`sla_category_title_${this.lang}`],
                severity_level: this.tableConv.resToColordItem(item, 'severity_level'),
                responsible_user: this.userConv.resToUserMinimalDTO(item, 'responsible_user'),
                compliance_status: this.tableConv.resToColordItem(item, 'compliance_status'),
                lifecycle_status: this.tableConv.resToColordItem(item, 'lifecycle_status'),
                client : item.client ,
                version: item.version,
                comment: item.comment,
                issue_date: item.issue_date,
                expiry_date: item.expiry_date,
                sa1_date: item.sa1_date,
                sa2_date: item.sa2_date,
            };
        });
    }
  
   
  
   
  
    formGroupToForm(formGroup: FormGroup): SLAContractFormDTO {
      return {
        id: formGroup.get('id')?.value,
        sla_category_id: formGroup.get('Category')?.value,
        lifecycle_status_id: parseInt(formGroup.get('LifecycleStatus')?.value, 10),
        title_en: formGroup.get('Title')?.value,
        title_ar: formGroup.get('Title')?.value,
        description: formGroup.get('Description')?.value,
        severity_level_id: parseInt(formGroup.get('SeverityLevel')?.value, 10),
        client: formGroup.get('Client')?.value,
        version: formGroup.get('Version')?.value,
        responsible_unit_id: 1, // TODO Data should be taken from form
        responsible_user_id: formGroup.get('ResponsibleUser')?.value,
        issue_date: formGroup.get('IssueDate')?.value,
        expiry_date: formGroup.get('ExpiryDate')?.value,
        sa1_date: formGroup.get('SA1Date')?.value,
        sa2_date: formGroup.get('SA2Date')?.value,
        document: formGroup.get('Document')?.value
      };
    }
    resToForm(response: any) {
      return {
        id : response.id,
        ReferenceNumber : response.reference_number,
        Category : response.sla_category?.id,
        LifecycleStatus : response.lifecycle_status?.id,
        Title : response[`title_${this.lang}`],
        Description : response.description,
        ResponsibleUnit : response.responsible_unit?.id,
        ResponsibleUser : response.responsible_user?.id,
        SeverityLevel : response.severity_level?.id,
        Client : response.client,
        Version : response.documents[0].version,
        IssueDate: response.documents[0].issue_date,
        ExpiryDate: response.documents[0].expiry_date,
        SA1Date : response.documents[0].sa1_date,
        SA2Date : response.documents[0].sa2_date,
        Document:response.documents[0],
      };
    }

    resToDetails(response: any): SLAContractDetailsDTO {
        return {
            id: response.id,
            reference_number: response.reference_number,
            title: response[`title_${this.lang}`],
            description: response.description,
            client: response.client,
            version: response.version,
            issue_date: response.documents[0].issue_date,
            expiry_date: response.documents[0].expiry_date,
            sa1_date: response.documents[0].sa1_date,
            sa2_date: response.documents[0].sa2_date,
            created_at: response.created_at,
            updated_at: response.updated_at ? response.updated_at : null,
            checks: response.checks,

            sla_category: convertResponseToSlaCategoryDetailsDTO(response.sla_category, this.lang),
            severity_level: convertResponseToSeverityLevelDetailsDTO(response.severity_level,this.lang),
            responsible_user: this.userConv.resObjToUserMinimalDTO(response.responsible_user),
            document: this.fileConv.resToFileDetailsDTO(response.documents[0]),
            status: convertResponseToLifeCycleStatusDetailsDTO(response.lifecycle_status, this.lang),
            created_by: this.userConv.resObjToUserMinimalDTO(response.created_by),
            updated_by: this.userConv.resObjToUserMinimalDTO(response.updated_by),
        }
    }
  
   
  }
  