import { Injectable } from '@angular/core';
import { BaseService } from '../../../../core/services/base.service';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MainBaseService } from '../../../../core/services/main-base-service';
import { SLAContractAssociatedItemSummaryDTO, SLAContractFormDTO, SLAContractListDTO } from '../dto/sla-contract.dto';
import { Observable } from 'rxjs';
import { ActionPlanStatusDTO } from '../../../settings/masters/dto/action-plan-status.dto';

@Injectable({
  providedIn: 'root'
})
export class SLAContractService extends MainBaseService<SLAContractListDTO, SLAContractFormDTO> {
  protected apiUrl = environment.apiUrl + '/sla-register';

  constructor(http: HttpClient) {
    super(http);
  }

  getDownloadURL(slaId: number, token: string): string {
    return `${this.apiUrl}/${slaId}/document-download?token=${token}`;
  }

  previewDocument(slaId: number, token: string): string {
    return `${this.apiUrl}/${slaId}/document-preview?token=${token}`;
  }

  getActionPlanStatusSummary(
    slaId: number
  ): Observable<ActionPlanStatusDTO[]> {
    const url = `${environment.apiUrl}/slas/${slaId}/action-plan-summary`
    return this.http.get<ActionPlanStatusDTO[]>(url);
  }

  getSlaContractAssociatedItemSummary(
    slaId: number
  ): Observable<SLAContractAssociatedItemSummaryDTO> {
    return this.http.get<SLAContractAssociatedItemSummaryDTO>(
      `${environment.apiUrl}/slas/${slaId}/associated-item-summary`
    );
  }

}
